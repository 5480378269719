<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Email Template Roles</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Email Template
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="4">
                  <v-select v-model="search.roles" :items="roles"
                            label="Roles"
                            item-text="text"
                            item-value="value"
                            outlined
                            dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      @click="searchEmailTemplate"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <table class="table">
                <thead>
                <th>Title</th>
                <th class="pr-3 text-center">Action</th>
                </thead>
                <tbody>
                <tr v-for="(role, index) of roles" :key="index">
                  <td>
                    <a  @click="openEmailTemplateList(role[1])">{{ role[0] }}
                      <i class="fas fa-circle dot-active">
                      </i>
                    </a>
                  </td>
                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a  v-if="checkIsAccessible('email-template', 'show')" @click="openEmailTemplateList(role[1])" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                </span>
                              <span class="navi-text">View</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import EmailTemplateService from "@/services/email-template/EmailTemplateService";

const emailTemplate = new EmailTemplateService();

export default {
  name: "email-templates",
  components: {},
  data() {
    return {
      email_templates: [],
      roles: [],
      page: null,
      total: null,
      perPage: null,
      search: {
        active: "active",
        roles: '',
      },
      roles: [
        {'text': 'Accountholder', 'value': 'account_holder'},
        {'text': 'General', 'value': 'general'},
        {'text': 'Candidate', 'value': 'candidate'},
        {'text': 'Examiner', 'value': 'examiner'},
        {'text': 'Supervisor', 'value': 'supervisor'},
        {'text': 'Online Marker', 'value': 'online_marker'},
        {'text': 'School Account', 'value': 'school_account'},
      ]
    };
  },
  mounted() {
    this.getEmailTemplateRoles();
  },
  methods: {
    getEmailTemplateRoles() {
      emailTemplate.emailTemplateRoles().then(response => {
        this.roles = response.data;
      });
    },
    __edit(id) {
      this.$router.push({
        name: "email-template-update",
        params: {id: id}
      });
    },
    searchEmailTemplate() {
      this.getTemplates();
    }, openEmailTemplateList(role) {
      this.$router.push({name: 'email-template', params: {role: role}})
    },
    deleteEducation(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item permanently?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            educationService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Deleted successfully");

                  this.getEducation();
                })
                .catch(error => {
                });
          }
        }
      });
    }
  }
};
</script>
